<template>
  <div class="clientes">
    <div class="d-flex justify-content-between">
      <div class="input-group mb-3">
        <button v-on:click="adicionarHandle()" class="btn btn-success">
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
        </button>
        <button v-on:click="handleOpenConfig()" class="btn btn-secondary">
          <font-awesome-icon :icon="['fas', 'cog']" fixed-width/>
        </button>
      </div>

      <div class="input-group mb-3">
        <input
            v-on:keyup.enter="handlePesquisa()"
            type="text"
            class="form-control"
            placeholder="Pesquisar"
            aria-label="Pesquisar"
            aria-describedby="button-addon2">
        <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
          <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
        </button>
      </div>
    </div>
    <div class="d-flex">
      <table class="table table-sm-pc table-striped table-hover">
        <thead>
          <tr>
            <th width="25">Identificação</th>
            <th width="85">Ordem</th>
            <th width="75"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="grupo in listaGrupos" :key="grupo.id">
              <td scope="row">{{grupo.description}}</td>
              <td>{{grupo.order}}</td>
              <td class="text-end">
                <button type="button" v-on:click="editaGrupoHandle(grupo)" class="btn btn-primary btn-sm me-1">
                  <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                </button>
                <button type="button" v-on:click="removeGrupoHandle(grupo)" class="btn btn-danger btn-sm">
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                </button>
              </td>

            </tr>
          </tbody>
      </table>
    </div>
    <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

    <!-- modal-edita-grupo -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-grupo" id="btn-modal-edita-grupo-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-edita-grupo" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!modalGrupo.form.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Cadastrar grupo do helpdesk
            </h5>
            <h5 v-if="!!modalGrupo.form.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
              Editando grupo do helpdesk
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-9 col-md-10">
                <label class="required">Identificação</label>
                <input type="text" v-model="modalGrupo.form.description" maxlength="150" class="form-control" placeholder="Nome do grupo">
              </div>
              <div class="col-12 col-sm-3 col-md-2">
                <label class="required">Ordem</label>
                <input type="number" v-model.number="modalGrupo.form.order" min="0" max="255" class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-grupo-fecha">Fechar</button>
            <button type="button"
                v-if="!modalGrupo.form.id"
                v-on:click="handleModalGrupoCadastrar()"
                v-bind:disabled="!podeSalvarGrupo()"
                class="btn btn-success">
              Cadastrar
            </button>
            <button type="button"
                v-if="!!modalGrupo.form.id"
                v-on:click="handleModalGrupoSalvar()"
                v-bind:disabled="!podeSalvarGrupo()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-grupo -->

    <!-- modal-config -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-config" id="btn-modal-config-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-config" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'cog']" fixed-width/>
              Configuração
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="required">Prazo inicial do ticket (dias)</label>
                <input type="number" v-model.number="modalConfig.form.dueDays" min="0" max="255" class="form-control" placeholder="">
              </div>
              <div class="col-12 col-sm-6">
                <label class="required">Auto arquivar (dias)</label>
                <input type="number" v-model.number="modalConfig.form.autoArchiveDays" min="0" max="255" class="form-control" placeholder="">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-config-fecha">Fechar</button>
            <button type="button"
                v-on:click="handleModalConfigSalvar()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-config -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'ConfigHelpdeskGrupos',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaGrupos: [],
      modalGrupo: {
        form: {
          id: undefined,
          description: '',
          order: 0,
        },
      },
      modalConfig: {
        form: {
          dueDays: 0,
          autoArchiveDays: 0,
        },
      },
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/ticket-groups?orderBy["order"]asc&${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.listaGrupos = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    adicionarHandle() {
      this.resetmodalGrupo();
      this.abreModalCadastroGrupo();
    },

    async editaGrupoHandle(grupo) {
      this.resetmodalGrupo();

      this.modalGrupo.form = clone(grupo);

      this.abreModalCadastroGrupo();
    },

    removeGrupoHandle(grupo) {
      dialogo.confirmacao(`Confirma remover o Plano de Acesso <b>${grupo.description}</b>?`).then(() => {
        loading(true);

        api.delete(`/ticket-groups/${grupo.id}`).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    abreModalCadastroGrupo() {
      document.getElementById('btn-modal-edita-grupo-abre').click();
    },

    resetmodalGrupo() {
      this.modalGrupo.form.id = undefined;
      this.modalGrupo.form.description = '';
      this.modalGrupo.form.order = 0;
    },

    podeSalvarGrupo() {
      switch (true) {
        case (this.modalGrupo.form.description.length < 3):
        case (this.modalGrupo.form.order <= 0):
          return false;

        default:
          return true;
      }
    },

    handleModalGrupoCadastrar() {
      const sendData = {
        id: undefined,
        description: this.modalGrupo.form.description,
        order: this.modalGrupo.form.order,
      };

      loading(true);

      api.post('/ticket-groups', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-grupo-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleModalGrupoSalvar() {
      const sendData = {
        id: this.modalGrupo.form.id,
        description: this.modalGrupo.form.description,
        order: this.modalGrupo.form.order,
      };

      loading(true);
      api.put(`/ticket-groups`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-grupo-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },


    //////////////////////////////////////////
    // modal config
    handleOpenConfig() {
      this.modalConfig.form.dueDays = 0;
      this.modalConfig.form.autoArchiveDays = 0;

      loading(true);
      api.get('/configs/helpdesk').then(res => {
        loading(false);
        this.modalConfig.form = res.data;
        document.getElementById('btn-modal-config-abre').click();
      }).catch(error => {
        loading(false);
        if (error.response && error.response.status === 404) {
          document.getElementById('btn-modal-config-abre').click();
          return;
        }
        dialogo.erroHttp(error);
      });
    },

    handleModalConfigSalvar() {
      dialogo.confirmacao(`Confirma salvar as alterações?`).then(() => {
        const sendData = this.modalConfig.form;

        loading(true);
        api.post(`/configs/helpdesk`, sendData).then(() => {
          loading(false);
          document.getElementById('btn-modal-config-fecha').click();
          dialogo.alerta('As configurações foram salvas com sucesso.');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },
    // modal config
    //////////////////////////////////////////
  },

  created() {
    this.handlePesquisa();
  }
}
</script>
