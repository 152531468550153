<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Configurações / Helpdesk</h1>
    <div class="form-group card-principal">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-config-grupos-usuarios-tab" data-bs-toggle="tab" href="#nav-config-grupos-usuarios" role="tab" aria-controls="nav-config-grupos-usuarios" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'users']" fixed-width class="me-1"/>
            Grupos de usuários
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <!-- config-grupos-usuarios's -->
        <div class="tab-pane fade show active" id="nav-config-grupos-usuarios" role="tabpanel" aria-labelledby="nav-config-grupos-usuarios-tab">
          <ConfigHelpdeskGrupos/>
        </div>
        <!-- config-grupos-usuarios's -->

        <!-- contatos -->
        <div class="tab-pane fade" id="nav-contatos" role="tabpanel" aria-labelledby="nav-contatos-tab">
        </div>
        <!-- contatos -->
      </div>
    </div>
  </div>
</template>

<script>
import ConfigHelpdeskGrupos from '@/views/ConfigHelpdeskGrupos.vue'

export default {
  name: 'ConfigHelpdesk',
  components: {
    ConfigHelpdeskGrupos,
  },
  data() {
    return {}
  },
}
</script>
